<template>
    <div class="comax-manage-orders-main">
        <div class="comax-manage-orders-main-title">
            <h2>מערכת הזמנות</h2>
        </div>
        <div class="comax-manage-orders-main-wrapper">
            <div class="comax-manage-orders-main-wrapper-options">
                <el-button @click="state='SuppliersManagement'" :type="state=='SuppliersManagement'?'success':'primary'">ניהול ספקים</el-button>
                <el-button @click="state='OrdersManagement'" style="margin: 0 5px;" :type="state=='OrdersManagement'?'success':'primary'">ניהול הזמנות</el-button>
            </div>
            <div class="comax-manage-orders-main-wrapper-content">
                <KeepAlive>
                    <component :is="state"/>
                </KeepAlive>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';

export default {
    components:{
        SuppliersManagement:defineAsyncComponent(() => import('./manage/SuppliersManagement.vue')),
        OrdersManagement:defineAsyncComponent(() => import('./manage/OrdersManagement.vue'))
    },
    setup () {
        
        const state = ref('SuppliersManagement')

        return {
            state
        }
    }
}
</script>

<style scoped>
    .comax-manage-orders-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #fff;
    }
    .comax-manage-orders-main-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: var(--secondary);
    }
    .comax-manage-orders-main-wrapper{
        width: 100%;
        max-width: 1200px;
        height: calc(100% - 50px);
        margin: 0 auto;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
    }
    .comax-manage-orders-main-wrapper-options{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .comax-manage-orders-main-wrapper-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
</style>